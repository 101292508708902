import React from 'react';

import BookList from '../../components/BookList/BookList';


function Home() {
  return (
    <>
    <BookList/>
    </>
 
  )
}

export default Home
